
const ProtocolDescription = () => {

    return (
        <div>
            <h1>Protocol Description</h1>
            <br/><br/>
            <h5>This page is under construction...</h5>
        </div>
    );
}

export default ProtocolDescription;